import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import history from '../../react-utils/history'
import request from 'superagent';


class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            password: '',
            errorMessage: '',
            // one of [default, overLimit, forget]
            formState: 'default'
        };

        this.form = React.createRef();

        window.localStorage.removeItem('user_permissions');
    }

    onChange = (name, event) => {
        const {value} = event.target;

        this.setState({
            [name]: value,
            errorMessage: ''
        })
    };

    onSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(this.form.current);

        request.post('/site/login')
            .set('X-Requested-With', 'XMLHttpRequest')
            .send(data)
            .then(data => {
                const response = JSON.parse(data.text);

                if (!response.status) {
                    this.setState({
                        errorMessage: response.message.error
                    })
                } else {
                    window.localStorage.setItem('user_permissions', JSON.stringify(response.message.user_permissions));
                    history.replace(response.message.url);
                }
            })
            .catch(error => {
                console.log(error)
            })
    };

    changeFormState = (newState, e) => {
        if (e) e.preventDefault();

        this.setState({
            formState: newState,
            errorMessage: ''
        })
    };

    getHeader() {
        const {formState} = this.state;

        switch (formState) {
            case 'default':
                return (
                    <div className="login-form__header">
                        <h5 className="login-form__header-title">
                            Вход
                        </h5>
                    </div>
                );

            case 'forget' || 'overLimit':
                return (
                    <div className="login-form__header login-form__header--back">
                        <button className="login-form__header-back-btn"
                                onClick={(e) => this.changeFormState('default', e)}>
                            <svg className="icon icon-back login-form__header-back-btn-icon">
                                <use xlinkHref="#icon-back"/>
                            </svg>
                        </button>
                        <h5 className="login-form__header-title">
                            Восстановление доступа
                        </h5>
                        <button className="login-form__header--close-btn">
                        </button>
                    </div>
                );

            default :
                return null
        }
    }

    getBody() {
        const {formState} = this.state;

        switch (formState) {
            case 'default':
                return (
                    <>
                        <label className="main-input__wrap main-input__wrap--top-label full-width mb-10">
                            <span className="main-input__text-label">Логин</span>
                            <input type="text"
                                   id="loginform-username"
                                   className={`main-input__text full-width ${this.state.errorMessage && 'main-input__text--invalid'}`}
                                   onChange={(e) => this.onChange('userName', e)}
                                   name="LoginForm[username]"/>
                        </label>
                        <label className="main-input__wrap main-input__wrap--top-label full-width mb-10">
                            <span className="main-input__text-label">Пароль</span>
                            <input type="password"
                                   id="loginform-password"
                                   className={`main-input__text full-width ${this.state.errorMessage && 'main-input__text--invalid'}`}
                                   onChange={(e) => this.onChange('password', e)}
                                   name="LoginForm[password]"/>
                        </label>

                        <a className="login-form__rout-link" onClick={() => this.changeFormState('forget')}>
                            Забыли логин или пароль?
                        </a>
                    </>
                );

            case 'overLimit':
                return (
                    <>
                        <p className="login-form__text">Был превышен лимит количества попыток входа и поэтому доступ был
                            временно заблокирован.</p>
                        <p className="login-form__text">Обратительсь к своему руководителю, чтобы восстановить
                            возможность входа. Либо повторите
                            попытки через 60 мин.</p>
                    </>
                );
            case 'forget':
                return (
                    <>
                        <p className="login-form__text">Если Вы забыли логин или пароль, – обратитесь к своему
                            руководителю для его изменения или
                            восстановления (если это будет возможно).</p>
                        <p className="login-form__text">Такие строгие меры сделаны в целях безопасности системы.</p>
                    </>
                );

            default:
                return null
        }
    }

    getFooter() {
        const {errorMessage, formState} = this.state;

        switch (formState) {
            case 'default':
                return (
                    <>
                        {errorMessage && <p>{errorMessage}</p>}
                        <button type="submit"
                                className="main-btn main-btn--accented"
                                onClick={this.onSubmit}
                                name="login-button">
                            Войти
                        </button>
                    </>
                );

            case 'forget':
                return (
                    <button type="submit"
                            className="main-btn main-btn--accented"
                            onClick={() => this.changeFormState('default')}
                            name="login-button">
                        Назад
                    </button>
                );

            default:
                return null
        }
    }

    render() {
        return (
            <form className="login-form"
                  ref={this.form}
                  onSubmit={this.onSubmit}
            >
                {this.getHeader()}
                <div className="login-form__logo">
                    <img className="login-form__logo-image" src="/images/dummy-logo.svg" alt=""/>
                </div>
                <div className="login-form__content">
                    {this.getBody()}
                </div>
                <div className="login-form__footer">
                    {this.getFooter()}
                </div>
            </form>
        )
    }
}

ReactDOM.render(<Index/>, document.querySelector('.login-wrapper'));


